<div id="panel-liste-echanges" class="ui-grid-row panel-header-category-transparent" style="margin-top:10px;">
  <div class="ui-g-12 no-padding-left">
    <h2 style="float: left;">
      <i class="zmdi zmdi-view-list"></i>
      Liste des échanges (page {{currentPage}} / {{nbTotalPages}})
      <button #btnExport type="button" pButton icon="pi pi-file" class="grey-button" style="margin-left: 20px;" label="Exporter" (click)="menuExport.toggle($event)"></button>
      <button #btnFermetureMasse type="button" pButton icon="pi pi-check" class="grey-button" style="margin-left: 20px;"
              label="Fermeture en masse" (click)="confirmFermetureMasse()"
              [disabled]="!auMoinsUnCoche"
      ></button>
    </h2>

    <p-slideMenu #menuExport [model]="exportItems" [popup]="true" [viewportHeight]="150" backLabel="Retour" appendTo="body"></p-slideMenu>

    <h2 style="float: right;">
    Nombre d'éléments {{firstElementNumber}} - {{lastElementNumber}} sur {{currentPage ? nbTotalEchanges : 0}} <i class="pi pi-refresh" style="margin-left:10px; cursor:pointer;" (click)="refresh()"></i>
    </h2>
  </div>
</div>

<p-table #dt class="table table-hover table-condensed table-liste-echanges" [columns]="cols" [value]="echangesList" dataKey="id_echange" autoLayout="true"
    [lazy]="true" [lazyLoadOnInit]="false" (onLazyLoad)="loadEchangesLazy($event)"
    [rows]="filtreEchange.limit" [totalRecords]="nbTotalEchanges" [loading]="loading"
    [paginator]="true" [rowsPerPageOptions]="rowsPerPageOptions" paginatorDropdownAppendTo= "body"
    (sortFunction)="customSort($event)" [customSort]="true"
    stateStorage="session" stateKey="state-echangesList"
    [(contextMenuSelection)]="selectedEchange" [contextMenu]="cmEchange" [selectionMode]="'single'" (onContextMenuSelect)="updateContextMenuEchange($event)">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="getSortableColumnDisabled(col.field)" legend="">
                <span>{{col.header}}</span>
                <p-sortIcon *ngIf="!getSortableColumnDisabled(col.field)" [field]="col.field"></p-sortIcon>
                <ng-container *ngIf="col.field === 'echange_fermable'">
                  <p-checkbox [binary]="true" [(ngModel)]="checkAllEchanges"
                              (ngModelChange)="basculerTout()"
                  ></p-checkbox>
                </ng-container>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [class]="getClassTrEchange(rowData)" [pSelectableRow]="rowData" [pContextMenuRow]="rowData" (click)="editEchange(rowData, $event)">
            <td *ngFor="let col of columns" [class]="getClassTdEchange(col.field)">
                <span *ngIf="col.field ==='echange_fermable'" (click)="$event.stopPropagation();">
                  <p-checkbox [binary]="true" name="fermables" [(ngModel)]="rowData.a_fermer" [disabled]="!rowData.echange_fermable" (ngModelChange)="nbrEchangesAFermer()"></p-checkbox>
                </span>
                <span *ngIf="col.field ==='marqueur'">
                  <div class="ui-g-3" *ngFor="let marqueur of rowData.echange_marqueurs">
                    <i [class]="marqueur.federationMarqueur.marqueur.libelle + ' ' + marqueur.federationMarqueur.marqueur.code_parametre"
                    [pTooltip]="marqueur.federationMarqueur.libelle_marqueur"></i>
                  </div>
                </span>
                <span *ngIf="col.field ==='created1' && getRowLabel('created', rowData).getTime()" >{{getRowLabel('created', rowData) | date: datePipeFormatWithoutTime}}</span>
                <span *ngIf="col.field ==='created2' && getRowLabel('created', rowData).getTime()">{{getRowLabel('created', rowData) | date: timeFormat}}</span>
                <span *ngIf="col.field === 'modified' && isDateValid(getRowLabel('modified', rowData))">{{ getRowLabel('modified', rowData) | date: datePipeFormatWithoutTime }}</span>
                <span *ngIf="col.field ==='statut_echange'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='astreinte'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='operateur'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='personne_sujet_nom'" [pTooltip]="getTooltip(col.field, rowData)" tooltipStyleClass="tooltip-utilisateurLibelle">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='personne_sujet_nature'" [pTooltip]="getTooltip(col.field, rowData)" tooltipStyleClass="tooltip-utilisateurLibelle"><i *ngIf="rowData['hasTiers']" class="zmdi zmdi-info"></i>{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='type_echange'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='categorie_echange'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='motif_echange'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='actions'" [pTooltip]="getTooltip(col.field, rowData)" tooltipStyleClass="tooltip-utilisateurLibelle">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='statut_echeance'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='firstDateEcheance'" pTooltip="Échéance de l'action la plus proche" tooltipStyleClass="tooltip-utilisateurLibelle">{{getRowLabel(col.field, rowData) | date: datePipeFormatWithoutTime}}</span>
                <span *ngIf="col.field ==='actions.responsable'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='association'">{{getRowLabel(col.field, rowData)}}</span>
            </td>
        </tr>
    </ng-template>

</p-table>

<p-contextMenu #cmEchange [model]="itemsContextMenu" class="contextMenuListeEchange" ></p-contextMenu>

<app-action-form-dialog *ngIf="newActionEchangeSelected && showPopupAction" [action]="newActionEchangeSelected" [showPopupAction]="showPopupAction" [formMode]="'create'" [saveNew]="true" (actionCreated)="onActionCreated($event)" (cancel)="cancelNewAction()"></app-action-form-dialog>

<p-dialog header="Impossible d'accéder à l'échange" [(visible)]="showPopupUnauthorizedEchange">
  Vous n'avez pas le droit d'accéder à cet échange.
</p-dialog>

<p-dialog header="Impossible d'exporter" [(visible)]="showPopupAlertExport">
  Impossible de générer un fichier Excel ou Csv supérieur à 1800 lignes. Il est nécessaire de préciser les filtres.
</p-dialog>

<p-dialog class="descriptif-complet" header="Descriptif complet" [(visible)]="displayDescriptifComplet" [modal]="true" [style]="{width: '50vw'}" [dismissableMask]="true">
  <p>{{ descriptifCompletContenu }}</p>
</p-dialog>

<p-dialog header="Liste des acteurs" [(visible)]="displayActeurs" [modal]="true" [style]="{width: '70vw'}" >
  <p-table [value]="acteurs" [scrollable]="true" scrollHeight="300px">
    <ng-template pTemplate="header">
      <tr>
        <th>Date de création</th>
        <th>Échéance</th>
        <th>Acteur</th>
        <th>Objet</th>
        <th>Descriptif</th>
        <th>Statut échéance</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-acteur>
      <tr>
        <td>{{ acteur.created  | date: datePipeFormatWithoutTime }}</td>
        <td>{{ acteur.echeance | date: datePipeFormatWithoutTime}}</td>
        <td>{{ acteur.responsable_libelle }}</td>
        <td>{{ acteur.objet_action }}</td>
        <td class="forcer-deux-lignes" (click)="montrerDescriptifComplet(acteur.description_action)">{{ acteur.description_action }}</td>
        <td>{{ getStatutEcheanceLisible(acteur.statut_echeance) }}</td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <div class="ui-rtl" dir="rtl">
      <button pButton type="button" class="green-button" label="Fermer" (click)="displayActeurs=false"></button>
    </div>
  </p-footer>
</p-dialog>

<p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000"></p-confirmDialog>
