import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { SelectItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { plainToClass } from 'class-transformer';

import { Utilisateur, Association, Federation, Agence, Parametre, Groupe } from '../../shared/_models';
import { AuthenticationService, UtilisateurService, AssociationService, FederationService, AgenceService, ParametreService, EchangeService, GroupeService } from '../../shared/_services';
import { FederationMarqueursService } from 'src/app/shared/_services/federation-marqueurs.service';
import { FederationMarqueur } from 'src/app/shared/_models/federation-marqueur';
import { FiltreEchange } from '../../shared/utils/filtre-echange';
import { Globals } from '../../shared/utils/globals';

@Component({
  selector: 'app-filtres-echange',
  templateUrl: './filtres-echange.component.html',
  styleUrls: ['./filtres-echange.component.css']
})
export class FiltresEchangeComponent implements OnInit {

  @Output()
  search = new EventEmitter<any>();

  @Output()
  showFilter = new EventEmitter<any>();

  queryParams: ParamMap;

  federationsList: Federation[];
  associationsList: Association[];
  associationsItemsList: SelectItem[];
  agencesList: Agence[];
  agencesItemsList: SelectItem[];
  utilisateursList: Utilisateur[];
  statutsList: Parametre[];
  naturesList: Parametre[];
  naturesTiersConcerneList: Parametre[];
  liensList: Parametre[];
  typesList: Parametre[];
  categoriesList: Parametre[];
  motifsList: Parametre[];
  motifsAlerteList: Parametre[];
  motifsDevisList: Parametre[];
  motifsEvaluationList: Parametre[];
  motifsReclamationInterventionList: Parametre[];
  motifsReclamationAssociationList: Parametre[];
  motifsRemonteeInformationList: Parametre[];

  tousMotifsList: Parametre[];

  marqueursList: FederationMarqueur[];
  groupesList: Groupe[];

  risquesSalarieList: Parametre[];
  risquesClientList: Parametre[];
  frequencesList: Parametre[];
  gravitesList: Parametre[];
  servicesList: Parametre[];
  finalitesList: Parametre[];
  organismesFinanciersList: Parametre[];
  typesAidesList: Parametre[];

  currentUtilisateur: Utilisateur;

  dateFormat = Globals.dateFormat;
  locale = Globals.dateLocale;

  filtreEchange: FiltreEchange;
  prioriteList = Globals.prioriteList;
  astreinteList = Globals.astreinteList;
  echeanceList = Globals.echeanceList;

  showFiltresRI: boolean;
  showFiltresDevis: boolean;
  utilisateursInactifs: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private utilisateurService: UtilisateurService,
    private federationService: FederationService,
    private associationService: AssociationService,
    private agenceService: AgenceService,
    private parametreService: ParametreService,
    private echangeService: EchangeService,
    private groupeService: GroupeService,
    private federationMarqueurService: FederationMarqueursService
  ) { }

  ngOnInit() {

    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    this.filtreEchange = this.echangeService.filtreEchange;
    this.updateShowFiltres();

    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.queryParams = params;

        this.getCategories();
        this.getUtilisateurs();

        this.updateFilter(params);
        this.getStatuts();

        this.getNatures();
        this.getTypes();

        this.getMotifs();
        this.getGroupes();

        this.getLiens();
        this.getRisquesSalarie();
        this.getRisquesClient();
        this.getFrequences();
        this.getGravites();
        this.getServices();
        this.getFinalitesDevis();
        this.getOrganismesFinanciers();
        this.getTypesAides();
        this.getFederations();
        this.getAssociations();
        this.getAgences();
        if (this.currentUtilisateur.perimetre !== 'National') {
          this.onChangeFederation();
        }
      }
    );
  }

  getFederations() {
    if (this.currentUtilisateur.perimetre === 'National') { // Si perimetre National, on cherche toutes les federations
      this.federationService.getAll().subscribe(federations => {
        this.federationsList = federations;
      });
    } else {
      this.federationsList = [this.currentUtilisateur.federation];
      this.filtreEchange.federation = [this.currentUtilisateur.federation];
    }
  }

  getAssociations() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      if (this.currentUtilisateur.type_acces === 'monoAsso' || (this.currentUtilisateur.perimetre === 'Association' && !this.currentUtilisateur.type_acces)) {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.filtreEchange.association = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
      } else if (this.currentUtilisateur.type_acces === 'multiAsso') {
        this.associationsList = this.currentUtilisateur.multiSiteAssociations;
      } else if (this.currentUtilisateur.type_acces === 'multiSite') {
        this.associationsList = [this.authenticationService.currentAssociationForUserMultiSite];
        this.filtreEchange.association = [this.authenticationService.currentAssociationForUserMultiSite];
      } else if (this.currentUtilisateur.perimetre === 'Federation') {
        this.associationService.getAssociationsByParams({ federation: this.currentUtilisateur.federation.id_federation })
          .subscribe((associations: Association[]) => {
            this.associationsList = associations;
            this.updateAssociationsItems();
          });
      } else if (this.currentUtilisateur.perimetre === 'Agence') {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.filtreEchange.association = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
      }
    } else if (this.filtreEchange.federation) {
      this.associationService.getAssociationsByParams({ federation: this.filtreEchange.federation[0].id_federation })
        .subscribe((associations: Association[]) => {
          this.associationsList = associations;
          this.updateAssociationsItems();
        });
    }

    if (this.associationsList) {
      this.updateAssociationsItems();
    }

  }

  getAgences() {
    if (this.currentUtilisateur.agences && this.currentUtilisateur.agences.length > 0) {
      this.agencesList = this.currentUtilisateur.agences;
      this.updateAgencesItems();
    } else if (this.filtreEchange.association) {
      this.agenceService.getAgencesByParams({ association: this.filtreEchange.association[0].id_association })
        .subscribe((agences: Agence[]) => {
          this.agencesList = agences;
          this.updateAgencesItems();
        });
    }
  }

  onSubmit() {
    this.search.emit(this.filtreEchange);
  }

  onChangeFederation() {
    if (this.filtreEchange.federation && this.filtreEchange.federation.length === 1) {
      this.getAssociations();
    } else {
      this.associationsList = null;
      this.filtreEchange.association = null;
      this.updateAssociationsItems();
    }
    let ids = this.filtreEchange.federation.map(f => {
      return f.id_federation;
    });
    this.federationMarqueurService.getFederationsMarqueursByFederations(ids).subscribe(res => {
      this.marqueursList = res;
    });
  }

  onChangeAssociation() {
    if (this.filtreEchange.association && this.filtreEchange.association.length === 1) {
      this.agenceService.getAgencesByParams({ association: this.filtreEchange.association[0].id_association })
        .subscribe((agences: Agence[]) => {
          this.agencesList = agences;
          this.updateAgencesItems();
        });
    } else {
      this.agencesList = null;
      this.filtreEchange.agence = null;
      this.updateAgencesItems();
    }
  }


  getUtilisateurs() {
    this.filtreEchange.operateur = [];
    this.utilisateurService.getAll(true, this.utilisateursInactifs)
      .subscribe((utilisateurs: Utilisateur[]) => {
        this.utilisateursList = plainToClass(Utilisateur, utilisateurs);
      });
  }

  getStatuts() {
    this.parametreService.getParametresByParams({ type: 'STATUT_E' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsList = parametres;
        // On ignore le filtre Astreinte :
        this.statutsList = this.statutsList.filter(statut => statut.code_parametre !== 'A');
        if (this.filtreEchange.statut_echange.length === 0 && !this.queryParams.get('statut_echange')) {
          // Si un statut n'a pas déjà été sélectionné auparavant et pas dans les queryParams
          let self = this;
          self.filtreEchange.statut_echange = []; // On réinitialise les statuts
          parametres.forEach(function (parametre) {
            if (parametre.is_default) {
              self.filtreEchange.statut_echange.push(parametre); // On definit le statut par defaut
            }
          });
        }

        this.onSubmit();
      });
  }

  getNatures() {
    this.parametreService.getParametresByParams({ type: 'NATURE' })
      .subscribe((parametres: Parametre[]) => {
        this.naturesList = parametres;
        this.getNaturesTiersConcerne();
      });
  }

  getNaturesTiersConcerne() {
    // Natures sans "Tiers pour" pour les tiers concernés
    let naturesTiersConcerneList = [];
    this.naturesList.forEach(function (nature) {
      if (nature.code_parametre !== 'TIE' && nature.code_parametre !== 'TIN') {
        naturesTiersConcerneList.push(nature);
      }
    });
    this.naturesTiersConcerneList = naturesTiersConcerneList;
  }

  getLiens() {
    this.parametreService.getParametresByParams({ type: 'LIEN' })
      .subscribe((parametres: Parametre[]) => {
        this.liensList = parametres;
      });
  }

  getTypes() {
    this.parametreService.getParametresByParams({ type: 'TYPE' })
      .subscribe((parametres: Parametre[]) => {
        this.typesList = parametres;
      });
  }

  getCategories() {
    this.parametreService.getParametresByParams({ type: 'CATEGORIE' })
      .subscribe((parametres: Parametre[]) => {
        this.categoriesList = parametres;
      });
  }

  getMotifs() {
    forkJoin(
      [
        this.parametreService.getParametresByParams({ type: 'MOTIF' }),
        this.parametreService.getParametresByParams({ type: 'MOTIF_DEVIS' }),
        this.parametreService.getParametresByParams({ type: 'MOTIF_RECLAMATION_INTERVENTION' }),
        this.parametreService.getParametresByParams({ type: 'MOTIF_RECLAMATION_ASSOCIATION' }),
        this.parametreService.getParametresByParams({ type: 'MOTIF_REMONTEE_INFORMATIONS' }),
      ]

    ).subscribe(([motifsList, motifsDevisList, motifsReclamationInterventionList, motifsReclamationAssociationList, motifsRemonteeInformationList]) => {
      this.motifsList = motifsList as Parametre[];
      this.motifsDevisList = motifsDevisList as Parametre[];
      this.motifsReclamationInterventionList = motifsReclamationInterventionList as Parametre[];
      this.motifsReclamationAssociationList = motifsReclamationAssociationList as Parametre[];
      this.motifsRemonteeInformationList = motifsRemonteeInformationList as Parametre[];

      let allMotifs = [];
      allMotifs = allMotifs
        .concat(this.motifsList)
        .concat(this.motifsDevisList)
        .concat(this.motifsReclamationInterventionList)
        .concat(this.motifsReclamationAssociationList)
        .concat(this.motifsRemonteeInformationList);

      this.tousMotifsList = allMotifs;
    });
  }

  getGroupes() {
    this.groupeService.getAll(true)
      .subscribe((groupes: Groupe[]) => {
        this.groupesList = groupes;
      });
  }

  getRisquesSalarie() {
    this.parametreService.getParametresByParams({ type: 'RISQUE_SALARIE' })
      .subscribe((parametres: Parametre[]) => {
        this.risquesSalarieList = parametres;
      });
  }

  getRisquesClient() {
    this.parametreService.getParametresByParams({ type: 'RISQUE_CLIENT' })
      .subscribe((parametres: Parametre[]) => {
        this.risquesClientList = parametres;
      });
  }

  getFrequences() {
    this.parametreService.getParametresByParams({ type: 'FREQUENCE' })
      .subscribe((parametres: Parametre[]) => {
        this.frequencesList = parametres;
      });
  }

  getGravites() {
    this.parametreService.getParametresByParams({ type: 'GRAVITE' })
      .subscribe((parametres: Parametre[]) => {
        this.gravitesList = parametres;
      });
  }

  getServices() {
    this.parametreService.getParametresByParams({ type: 'SERVICE' })
      .subscribe((parametres: Parametre[]) => {
        this.servicesList = parametres;
      });
  }

  getFinalitesDevis() {
    this.parametreService.getParametresByParams({ type: 'FINALITE_DEVIS' })
      .subscribe((parametres: Parametre[]) => {
        this.finalitesList = parametres;
      });
  }

  getOrganismesFinanciers() {
    this.parametreService.getParametresByParams({ type: 'ORGANISME_FINANCEUR' })
      .subscribe((parametres: Parametre[]) => {
        this.organismesFinanciersList = parametres;
      });
  }

  getTypesAides() {
    if (this.filtreEchange.organisme_financier) {
      this.parametreService.getParametresByParams({ type: this.filtreEchange.organisme_financier.type_enfant })
        .subscribe((parametres: Parametre[]) => {
          this.typesAidesList = parametres;
        });
    } else {
      this.typesAidesList = [];
    }
  }

  onChangeCategorie() {
    // Réinitialisation des filtres spécifiques
    this.filtreEchange.risques_salarie = null;
    this.filtreEchange.risques_client = null;
    this.filtreEchange.frequence = null;
    this.filtreEchange.gravite = null;
    this.filtreEchange.services = null;
    this.filtreEchange.finalite_echange = null;
    this.filtreEchange.organisme_financier = null;
    this.filtreEchange.type_aide = null;
    this.typesAidesList = [];

    // Affichage ou non des filtres spécifiques
    this.updateShowFiltres();
  }

  initFilter(updateLimit = true) {
    let limitOld = this.filtreEchange.limit;

    this.filtreEchange = new FiltreEchange();
    if (!updateLimit) {
      this.filtreEchange.limit = limitOld;
    }

    this.getFederations(); // On initialise les federations par defaut (pour utilisateur multiSite ou monoAsso)
    this.getAssociations(); // On initialise les associations par defaut (pour utilisateur multiSite ou monoAsso)
    this.getAgences(); // On initialise les associations par defaut (pour utilisateur multiSite ou monoAsso)
    this.getStatuts(); // On initialise le statut par défaut

    this.updateShowFiltres();
  }

  updateFilter(params: ParamMap) {

    if (params.keys.length > 0) {
      this.filtreEchange = new FiltreEchange();
    }

    if (params.has('priorite')) {
      this.filtreEchange.priorite = [params.get('priorite')];
    }
    if (params.has('astreinte')) {
      this.filtreEchange.astreinte = [params.get('astreinte')];
    }

    if (params.has('statut_echange')) {
      this.filtreEchange.statut_echange = []; // On réinitialise les statuts
      let self = this;
      params.getAll('statut_echange').forEach(function (p) {
        let statutFiltre = new Parametre();
        statutFiltre.id_parametre = Number(p);
        self.filtreEchange.statut_echange.push(statutFiltre);
      });
    }

    if (params.has('statut_echeance')) {
      this.filtreEchange.statut_echeance = [params.get('statut_echeance')];
    }

    if (params.has('categorie_echange')) {
      this.filtreEchange.categorie_echange = []; // On réinitialise les categories
      let categorieFiltre = new Parametre();
      categorieFiltre.id_parametre = Number(params.get('categorie_echange'));
      this.filtreEchange.categorie_echange.push(categorieFiltre); // On definit la categorie du queryParam
    }

    if (params.has('personne_sujet_nature')) {
      this.filtreEchange.personne_sujet_nature = []; // On réinitialise les statuts
      let self = this;
      params.getAll('personne_sujet_nature').forEach(function (p) {
        let statutFiltre = new Parametre();
        statutFiltre.id_parametre = Number(p);
        self.filtreEchange.personne_sujet_nature.push(statutFiltre);
      });
    }

    if (params.has('type_echange')) {
      this.filtreEchange.type_echange = [];
      let self = this;
      params.getAll('type_echange').forEach(function (p) {
        let typeFiltre = new Parametre();
        typeFiltre.id_parametre = Number(p);
        self.filtreEchange.type_echange.push(typeFiltre);
      });
    }

    if (params.has('federation')) {
      this.filtreEchange.federation = []; // On réinitialise les federations
      let federationFiltre = new Federation();
      federationFiltre.id_federation = Number(params.get('federation'));
      this.filtreEchange.federation.push(federationFiltre); // On definit la federation du queryParam
    }

    if (params.has('association')) {
      this.filtreEchange.association = []; // On réinitialise les associations
      let associationFiltre = new Association();
      associationFiltre.id_association = Number(params.get('association'));
      this.filtreEchange.association.push(associationFiltre); // On definit l'association du queryParam
    }

    if (params.has('agence')) {
      this.filtreEchange.agence = []; // On réinitialise les agences
      let agenceFiltre = new Agence();
      agenceFiltre.id_agence = Number(params.get('agence'));
      this.filtreEchange.agence.push(agenceFiltre); // On definit l'agence du queryParam
    }

    if (params.has('operateur')) {
      if (params.get('operateur') === 'me') {
        this.filtreEchange.operateur = [this.currentUtilisateur];
      } else {
        let operateurFiltre = new Utilisateur();
        operateurFiltre.id_utilisateur = Number(params.get('operateur'));
        this.filtreEchange.operateur = [operateurFiltre];
      }
    }
  }

  getClassMarqueur(icon, iconColor) {
    let className = Globals.defaultIconClassName;
    className += ' ' + icon;
    className += ' ' + iconColor;

    return className;
  }

  isFederationReadOnly() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      return true;
    }
    return false;
  }

  isAssociationReadOnly() {
    if ((this.currentUtilisateur.perimetre === 'Association' && (!this.currentUtilisateur.type_acces || this.currentUtilisateur.type_acces === 'multiSite' || this.currentUtilisateur.type_acces === 'monoAsso'))
      || (this.filtreEchange.federation && this.filtreEchange.federation.length > 1)
      || this.currentUtilisateur.perimetre === 'Agence') {
      return true;
    }
    return false;
  }

  isAgenceReadOnly() {
    if (this.filtreEchange.federation && this.filtreEchange.federation.length > 1
      || (this.filtreEchange.association && this.filtreEchange.association.length > 1)) {
      return true;
    }
    return false;
  }

  updateShowFiltres() {
    let showFiltresRI = false;
    let showFiltresDevis = false;

    if (this.filtreEchange.categorie_echange && this.filtreEchange.categorie_echange.length === 1) {
      if (this.filtreEchange.categorie_echange[0].code_parametre === 'REMINF') { // Remontée Informations
        showFiltresRI = true;
      } else if (this.filtreEchange.categorie_echange[0].code_parametre === 'DEV') { // Devis
        showFiltresDevis = true;
      }
    }

    this.showFiltresRI = showFiltresRI;
    this.showFiltresDevis = showFiltresDevis;
  }

  updateAssociationsItems() {
    let associationsItemsList = [];
    if (this.associationsList) {
      this.associationsList.forEach(function (association) {
        if (association.disabled) {
          associationsItemsList.push({ label: association.libelle, value: association, styleClass: 'italic' });
        } else {
          associationsItemsList.push({ label: association.libelle, value: association });
        }
      });
    }

    this.associationsItemsList = associationsItemsList;
  }

  updateAgencesItems() {
    let agencesItemsList = [];
    if (this.agencesList) {
      this.agencesList.forEach(function (agence) {
        if (agence.disabled) {
          agencesItemsList.push({ label: agence.libelle, value: agence, styleClass: 'italic' });
        } else {
          agencesItemsList.push({ label: agence.libelle, value: agence });
        }
      });
    }

    this.agencesItemsList = agencesItemsList;
  }

  toggleShowFilter() {
    this.showFilter.emit();
  }

}
