import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DocumentationModule} from './documentation/documentation.module';
import {EchangeModule} from './echange/echange.module';
import {RechercheActionModule} from './recherche-action/recherche-action.module';
import {RechercheEchangeModule} from './recherche-echange/recherche-echange.module';
import {SelectAssociationComponent} from './select-association/select-association.component';
import {AuthJwtModule} from './shared/auth/auth-jwt.module';
import {AuthMode} from './shared/auth/enums/auth-mode.enum';
import { AdminModule } from './admin/admin.module';
import {SharedModule} from './shared/shared.module';
import {TableauBordModule} from './tableau-bord/tableau-bord.module';


// NSI Components
import {NsiAdminFederationsModule} from 'nsi-admin-federations';
import {NsiAdminProfilsModule} from 'nsi-admin-profils';
import {NsiAdminGroupesModule} from 'nsi-admin-groupes';
import {NsiAdminUtilisateursModule} from 'nsi-admin-utilisateurs';
import {NsiAdminAssociationsModule} from 'nsi-admin-associations';
import {NsiAdminAgencesModule} from 'nsi-admin-agences';
import {NsiAdminParametresModule} from 'nsi-admin-parametres';

@NgModule({
  declarations: [
    AppComponent,
    SelectAssociationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    RechercheEchangeModule,
    RechercheActionModule,
    EchangeModule,
    DocumentationModule,
    TableauBordModule,
    AdminModule,
    AppRoutingModule,
    NsiAdminFederationsModule.forRoot({ environment: environment }),
    NsiAdminProfilsModule.forRoot({ environment: environment }),
    NsiAdminGroupesModule.forRoot({ environment: environment }),
    NsiAdminUtilisateursModule.forRoot({ environment: environment}),
    NsiAdminAssociationsModule.forRoot({ environment: environment }),
    NsiAdminAgencesModule.forRoot({ environment: environment }),
    NsiAdminParametresModule.forRoot({ environment: environment }),
    AuthJwtModule.forRoot({
      mode: AuthMode.JWT,
      method: environment.keycloak.method,
      appName: 'Tracéo',
      storage: environment.keycloak.storage,
      allowedDomains: environment.keycloak.allowedDomains,
      urlAuth: environment.keycloak.urlAuth,
      urlRefresh: environment.keycloak.urlRefresh,
      secondsBeforeRefresh: 60,
      forbidden: () => window.location.replace(environment.keycloak.disconnectUrl)
    })
  ],
  providers: [
    Title, // on fournit le service 'Title' à l'ensemble de l'application
    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
