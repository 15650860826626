import {HttpClient, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {plainToClass} from 'class-transformer';

import { SelectItem } from 'primeng/api';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { Utilisateur } from '../_models';

import { FiltreUtilisateur } from '../utils/filtre-utilisateur';
import { AuthenticationService } from './authentication.service';
import { DroitService } from './droit.service';

@Injectable({ providedIn: 'root' })
export class UtilisateurService {

  filtreUtilisateur = new FiltreUtilisateur();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private droitService: DroitService
  ) {
  }

  /** GET users */
  checkLogin(login): Observable<Utilisateur[]> {
    let params = new HttpParams();
    params = params.append('login', login);

    return this.http.get<Utilisateur[]>(`${environment.apiHost}/${environment.apiNamespace}/users`, { params: params });
  }

  async getDroitsModules(utilisateur: Utilisateur): Promise<Utilisateur> {
    try {
      let vigilance;
      let main_courante;
      let evaluation;

      if (environment.app === 'main_courante') {
        vigilance = await this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/vigilance/utilisateur`).toPromise();
        utilisateur.acces_alertes = vigilance.acces_alertes ?? false;
        evaluation = await this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/evaluations/utilisateur`).toPromise();
        utilisateur.acces_evaluations = evaluation.acces_evaluations ?? false;
      } else if (environment.app === 'vigilance') {
        evaluation = await this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/evaluations/utilisateur`).toPromise();
        utilisateur.acces_evaluations = evaluation.acces_evaluations ?? false;
        main_courante = await this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/maincourante/utilisateur`).toPromise();
        utilisateur.acces_echanges = main_courante.acces_echanges ?? false;
      } else if (environment.app === 'evaluations') {
        vigilance = await this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/vigilance/utilisateur`).toPromise();
        utilisateur.acces_alertes = vigilance.acces_alertes ?? false;
        main_courante = await this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/maincourante/utilisateur`).toPromise();
        utilisateur.acces_echanges = main_courante.acces_echanges ?? false;
      }

      return utilisateur;
    } catch (error) {
      // Handle errors here
      throw error;
    }
  }



  /** POST utilisateurs */
  createUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur> {
    return this.http.post<any>(
      `${environment.apiHost}/${environment.apiNamespace}/utilisateurs`,
      plainToClass(Utilisateur, utilisateur).serialize()
    ).pipe(
      tap((utilisateurCreated: Utilisateur) => {
        utilisateurCreated = plainToClass(Utilisateur, utilisateurCreated);

        if (utilisateur.multiSiteAssociations && utilisateur.multiSiteAssociations.length > 0) {
          this.droitService.createDroits(utilisateurCreated.id_utilisateur, utilisateur.multiSiteAssociations).subscribe(
            (droitsList) => {
              console.log('creation droits');
              console.log(droitsList);
            },
            error => {
              console.log(error);
            }
          );
        }
      }),
      catchError(this.handleError)
    );
  }

  getAll(restricted_datas = false, utilisateursInactifs: boolean = false): Observable<Utilisateur[]> {
    let params = new HttpParams();
    if (restricted_datas) {
      params = params.append('restricted_datas', 'true');
    }
    params = params.append('utilisateurs_inactifs', utilisateursInactifs ? 'true' : 'false');
    if (params.getAll.length > 0) {
      return this.http.get<Utilisateur[]>(`${environment.apiHost}/${environment.apiNamespace}/utilisateurs`, { params: params });
    } else {
      return this.http.get<Utilisateur[]>(`${environment.apiHost}/${environment.apiNamespace}/utilisateurs`);
    }
  }

  /** GET evaluateurs */
  getEvaluateursByParams(paramsValue: any = [], restricted_datas = false): Observable<Utilisateur[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    if (restricted_datas) {
      params = params.append('restricted_datas', 'true');
    }
    return this.http.get<Utilisateur[]>(`${environment.apiHost}/${environment.apiNamespace}/evaluateurs`, { params: params });
  }

  getModeDroitsByPerimetre(perimetre: string = 'all'): SelectItem[] {
    let modeDroits = [];

    modeDroits.push({ label: 'Lecteur', value: 'Lecteur' });
    modeDroits.push({ label: 'Astreinte', value: 'Astreinte' });

    if (perimetre === 'all') {
      modeDroits.push({ label: 'Acteur', value: 'Acteur' });
      modeDroits.push({ label: 'Personia', value: 'Personia' });
      modeDroits.push({ label: 'Accueil', value: 'Accueil' });
    } else {
      if (perimetre !== 'National') {
        modeDroits.push({ label: 'Acteur', value: 'Acteur' });
        modeDroits.push({ label: 'Accueil', value: 'Accueil' });
      } else {
        modeDroits.push({ label: 'Personia', value: 'Personia' });
      }
    }
    return modeDroits;
  }

  /** GET utilisateurs/count */
  getNbUtilisateursByParams(paramsValue: FiltreUtilisateur): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/utilisateurs/count`, { params: params });
  }

  getPerimetres(type_perimetre = ''): SelectItem[] {
    let currentUser = this.authenticationService.currentUtilisateurValue;
    let perimetres = [];

    if (type_perimetre === 'personia') {
      perimetres.push({ label: 'National', value: 'National' });
    } else {
      if (type_perimetre === 'utilisateur') {
        perimetres.push({ label: 'Agence', value: 'Agence' });
      }

      if (currentUser.perimetre !== 'Agence') {
        perimetres.push({ label: 'Association', value: 'Association' });
      }

      if (currentUser.perimetre === 'National') {
        perimetres.push({ label: 'National', value: 'National' });
        perimetres.push({ label: 'Fédération', value: 'Federation' });
      } else if (currentUser.perimetre === 'Federation') {
        perimetres.push({ label: 'Fédération', value: 'Federation' });
        perimetres.push({ label: 'National', value: 'National', disabled: true });
      } else if (currentUser.perimetre === 'Association') {
        perimetres.push({ label: 'Fédération', value: 'Federation', disabled: true });
        perimetres.push({ label: 'National', value: 'National', disabled: true });
      }
    }

    return perimetres;
  }

  /** GET responsables */
  getResponsablesByParams(paramsValue: any = [], restricted_datas = false): Observable<Utilisateur[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    if (restricted_datas) {
      params = params.append('restricted_datas', 'true');
    }
    return this.http.get<Utilisateur[]>(`${environment.apiHost}/${environment.apiNamespace}/responsables`, { params: params });
  }

  /** GET utilisateurs/:id */
  getUtilisateur(id_utilisateur: number): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/utilisateurs/${id_utilisateur}`);
  }

  /** GET utilisateurs */
  getUtilisateursByParams(paramsValue: FiltreUtilisateur, for_export = false, restricted_datas = false): Observable<Utilisateur[]> {
    this.filtreUtilisateur = paramsValue;
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    if (for_export) {
      params = params.append('for_export', 'true');
    }
    if (restricted_datas) {
      params = params.append('restricted_datas', 'true');
    }
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<Utilisateur[]>(`${environment.apiHost}/${environment.apiNamespace}/utilisateurs`, { params: params });
  }

  initFiltreUtilisateur() {
    this.filtreUtilisateur = new FiltreUtilisateur();
  }

  /** PATCH utilisateurs */
  patchUtilisateur(id_utilisateur, params): Observable<Utilisateur> {
    return this.http.patch<any>(
      `${environment.apiHost}/${environment.apiNamespace}/utilisateurs/${id_utilisateur}`,
      params
    );
  }

  /** DELETE utilisateurs */
  removeUtilisateur(id_utilisateur: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/utilisateurs/${id_utilisateur}`,
      { observe: 'response' }).pipe(
        catchError(this.handleError)
      );
  }

  /** PATCH updateProfile */
  updateProfile(utilisateur: Utilisateur): Observable<Utilisateur> {
    let profileParams = {
      preference_affichage: utilisateur.preference_affichage,
      preferences_tableau_bord: utilisateur.preferences_tableau_bord
    };

    return this.http.patch<any>(
      `${environment.apiHost}/${environment.apiNamespace}/updateProfile/${utilisateur.id_utilisateur}`,
      profileParams
    );
  }

  /** PUT utilisateurs */
  updateUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur> {
    return this.http.put<Utilisateur>(
      `${environment.apiHost}/${environment.apiNamespace}/utilisateurs/${utilisateur.id_utilisateur}`,
      plainToClass(Utilisateur, utilisateur).serialize()
    ).pipe(
      switchMap((utilisateurUpdated: Utilisateur) => {
        if (utilisateur.multiSiteAssociations && utilisateur.multiSiteAssociations.length > 0 && this.authenticationService.currentUtilisateurValue.est_administrateur === 'O') {
          return this.droitService.createDroits(utilisateurUpdated.id_utilisateur, utilisateur.multiSiteAssociations).pipe(
            mapTo(utilisateurUpdated)
          );
        }

        return of(utilisateurUpdated);
      }),
      tap((utilisateurUpdated: Utilisateur) => {
        // reload la page pour prendre en compte les changements si l'utilisateur connecté est celui qui a été modifié
        if (utilisateurUpdated.id_utilisateur === this.authenticationService.currentUtilisateurValue.id_utilisateur) {
          window.location.reload();
        }
      }),
      catchError(this.handleError)
    );
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

  /** GET partenaires */
  getPartenairesByParams(paramsValue: any = []): Observable<any> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }

    return this.http.get(`${environment.apiHost}/${environment.apiNamespace}/search/partenaires`, { params: params });
  }
}
