import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { classToClass, plainToClass } from 'class-transformer';

import { LazyLoadEvent, MenuItem, MessageService , ConfirmationService} from 'primeng/api';

import { forkJoin } from 'rxjs';

import { Globals } from '../../shared/utils/globals';
import { Action, DroitModule, Echange, Parametre, Utilisateur } from '../_models';

import { ActionService, AuthenticationService, EchangeService, ExportService, ParametreService } from '../_services';
import { FiltreEchange } from '../utils/filtre-echange';
import { EchangeMarqueursService } from '../_services/echange-marqueurs.service';

@Component({
  selector: 'app-liste-echanges',
  templateUrl: './liste-echanges.component.html',
  styleUrls: ['./liste-echanges.component.css'],
  providers: [ConfirmationService]
})
export class ListeEchangesComponent implements OnInit {

  @Input()
  filtreEchange: FiltreEchange = new FiltreEchange(); // propriété d'entrée du composant : le filtre echange

  @Input()
  updateFiltre = true; // propriété d'entrée du composant : mise a jour du filtre globale des echanges

  @Output()
  refreshList = new EventEmitter<any>();

  @ViewChild('dt', { static: true }) dt; // Composant datatable qui affiche la liste des echanges

  currentUtilisateur: Utilisateur;
  nbTotalEchanges = 0;
  echangesList: Echange[] = [];
  cols: any[];
  loading: boolean;
  rowsPerPageOptions = [20, 50, 100];
  itemsContextMenu: MenuItem[];
  selectedEchange: Echange;
  statutsEchangeList: Parametre[];
  statutsActionList: Parametre[];
  exportItems: MenuItem[];

  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  timeFormat = Globals.timeFormat;

  showPopupAction = false;
  showPopupUnauthorizedEchange = false;
  showPopupAlertExport = false;
  acteurs: any[];
  displayActeurs = false;
  displayDescriptifComplet = false;
  descriptifCompletContenu = '';
  checkAllEchanges = false;  // Valeur de la checkbox dans le TH
  auMoinsUnCoche = false; // Au moins une checkbox cochée.

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private echangeService: EchangeService,
    private actionService: ActionService,
    private parametreService: ParametreService,
    private exportService: ExportService,
    private messageService: MessageService,
    private echangeMarqueurService: EchangeMarqueursService,
    private confirmationService: ConfirmationService
  ) {
  }

  get currentPage(): number {
    let currentPage = 0;
    if (this.echangesList.length > 0 && this.dt.rows > 0) {
      currentPage = this.dt.first / this.dt.rows + 1;
    }
    return currentPage;
  }

  get firstElementNumber() {
    if (!this.nbTotalEchanges || !this.currentPage) {
      return 0;
    }

    return this.filtreEchange.limit * (this.currentPage - 1) + 1;
  }

  get lastElementNumber() {
    if (!this.nbTotalEchanges || !this.currentPage) {
      return 0;
    }

    let lastElementNumber = this.firstElementNumber + this.filtreEchange.limit - 1;

    if (lastElementNumber < this.nbTotalEchanges) {
      return lastElementNumber;
    }
    return this.nbTotalEchanges;
  }

  get nbTotalPages(): number {
    let nbTotalPages = 0;
    if (this.nbTotalEchanges > 0 && this.echangesList.length > 0 && this.dt.rows > 0) {
      nbTotalPages = Math.ceil(this.nbTotalEchanges / this.dt.rows); // On arrondi au supérieur
    }
    return nbTotalPages;
  }

  get newActionEchangeSelected() {
    let newAction = null;
    if (this.selectedEchange) {
      newAction = new Action();
      newAction.echange = this.selectedEchange;
      newAction.redacteur = this.currentUtilisateur;
      newAction.responsable = this.currentUtilisateur;
      this.statutsActionList.forEach(function (statutAction) {
        if (statutAction.is_default) {
          newAction.statut_action = statutAction; // On definit le statut par defaut
        }
      });
      newAction.objet_action = this.selectedEchange.objet_echange;
    }

    return newAction;
  }

  addAction() {
    this.showPopupAction = true;
  }

  cancelNewAction() {
    this.showPopupAction = false;
  }

  closeAllActions() {
    let updateActionList = [];
    let params = {};
    this.statutsActionList.forEach((statutAction) => {
      if (statutAction.code_parametre === 'F') {
        this.selectedEchange.actions.forEach((action) => {
          if (action.statut_action.code_parametre !== 'F') {
            action.id_echange = this.selectedEchange.id_echange;
            action.statut_action = statutAction; // On met le statut de l'action a fermé
            params = { statut_action: statutAction.id_parametre };
            updateActionList.push(this.actionService.patchAction(params, action.id_action));
          }
        });
      }
    });
    // On met à jour les actions
    if (updateActionList.length > 0) {
      forkJoin(
        updateActionList
      ).subscribe((actionsList) => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Actions fermées avec succès.' });
        this.searchEchange(this.filtreEchange); // On recharge la liste des echanges
      });
    }
  }

  closeEchange() {
    let params = {};
    this.statutsEchangeList.forEach((statut) => {
      if (statut.code_parametre === 'F') {
        this.selectedEchange.statut_echange = statut; // On met le statut de l'échange a fermé
        params = { statut_echange: statut.id_parametre };
      }
    });
    // On met à jour l'échange
    this.echangeService.patchEchange(params, this.selectedEchange.id_echange)
      .subscribe((echangeUpdated: Echange) => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Echange fermé avec succès.' });
        this.searchEchange(this.filtreEchange); // On recharge la liste des echanges
      });
  }

  customSort(event) {
    this.filtreEchange.sort_property = event.field;
    this.filtreEchange.sort_direction = event.order;

    this.searchEchange(this.filtreEchange);
  }

  disabledCloseAllActionsMenu(selectedEchange: Echange) {
    // Cas particulier "reponsable" => peux fermer toutes les actions si toutes les action ouvertes lui sont attribué
    if (!selectedEchange.isClosed(this.currentUtilisateur) && selectedEchange.openActionsLength >= 0) {
      let actions = selectedEchange.actions;
      let actionToAnother = false;
      actions.forEach((action) => {
        if (this.currentUtilisateur.id_utilisateur !== action.responsable.id_utilisateur && action.statut_action.code_parametre === 'E') {
          actionToAnother = true;
        }
      });
      return actionToAnother; // Menu inactif si au moins une action ouverte est attribuée à quelqu'un d'autre
    }

    return selectedEchange.isClosed(this.currentUtilisateur) || selectedEchange.openActionsLength === 0;
  }

  editEchange(rowData, event) {
    if ((rowData['statut_echange'].code_parametre === 'B' && this.currentUtilisateur.est_administrateur === 'O' && !this.currentUtilisateur.isInPerimetreAssociation(rowData['association'], rowData['federation']))
      || (rowData['statut_echange'].code_parametre === 'B' && this.currentUtilisateur.est_administrateur === 'N' && this.currentUtilisateur.id_utilisateur !== rowData['operateur'].id_utilisateur)) {
      this.showPopupUnauthorizedEchange = true;

      return false;
    }

    if ((rowData['statut_echange'].code_parametre === 'B' && this.currentUtilisateur.est_administrateur === 'O' && this.currentUtilisateur.isInPerimetreAssociation(rowData['association'], rowData['federation']))
      || (rowData['statut_echange'].code_parametre === 'B' && this.currentUtilisateur.est_administrateur === 'N' && this.currentUtilisateur.id_utilisateur === rowData['operateur'].id_utilisateur)
      || rowData['statut_echange'].code_parametre === 'O'
      || rowData['statut_echange'].code_parametre === 'A'
      || rowData['statut_echange'].code_parametre === 'F') {
      let url = this.router.createUrlTree(['/in/echange/' + rowData['id_echange']]);
      window.open(url.toString(), '_blank');
    }
  }

  exportCsv(allEchanges = true) {
    if (allEchanges) {
      let filtreEchangeAll = classToClass(this.filtreEchange); // On clone le filtre echange
      filtreEchangeAll.limit = null;  // On enleve la limite pour récupérer tous les echanges
      filtreEchangeAll.offset = null; // On réinitialise l'offset
      if (this.nbTotalEchanges < 18000) {
        this.echangeService.getEchangesByParams(filtreEchangeAll)
          .subscribe((echanges: Echange[]) => {
            if (!this.showPopupAlertExport) {
              this.exportService.exportAsCsvFile(this.getDataForExport(plainToClass(Echange, echanges)), 'echanges');
            }
          });
      } else {
        this.showPopupAlertExport = true;
      }
    } else {
      this.exportService.exportAsCsvFile(this.getDataForExport(), 'echanges');
    }
  }

  exportExcel(allEchanges = true) {
    if (allEchanges) {
      let filtreEchangeAll = classToClass(this.filtreEchange); // On clone le filtre echange
      filtreEchangeAll.limit = null;  // On enleve la limite pour récupérer tous les echanges
      filtreEchangeAll.offset = null; // On réinitialise l'offset
      if (this.nbTotalEchanges < 18000) {
        this.echangeService.getEchangesByParams(filtreEchangeAll)
          .subscribe((echanges: Echange[]) => {
            if (!this.showPopupAlertExport) {
              this.exportService.exportAsExcelFile(this.getDataForExport(plainToClass(Echange, echanges)), 'echanges');
            }
          });
      } else {
        this.showPopupAlertExport = true;
      }
    } else {
      this.exportService.exportAsExcelFile(this.getDataForExport(), 'echanges');
    }
  }

  getClassTdEchange(field) {
    if (field === 'statut_echange' || field === 'categorie_echange' || field === 'motif_echange' || field === 'actions' || field === 'statut_echeance') {
      return 'td-centered';
    }
    return '';
  }

  getClassTrEchange(rowData) {
    let classTrEchange = 'selectable';

    if (rowData['priorite'] === 'U') {
      classTrEchange += ' tr-echange-urgent';
    }
    if (rowData['statut_echange'].code_parametre === 'F') {
      classTrEchange += ' tr-echange-closed';
    }
    if (rowData['firstDateEcheance'] !== null && rowData['firstDateEcheance'] < Date.now()) {
      classTrEchange += ' tr-echange-echeancePassed';
    }

    if (rowData['statut_echeance'] !== null && rowData['statut_echeance'] === 'D') {
      classTrEchange += ' tr-echange-echeancePassed';
    }

    if (rowData['statut_echeance'] !== null && rowData['statut_echeance'] === 'U') {
      classTrEchange += ' tr-echange-echeanceUrgente';
    }

    if (rowData['statut_echange'].code_parametre === 'B') {
      classTrEchange += ' tr-echange-brouillon';
    }

    if ((rowData['statut_echange'].code_parametre === 'B' && this.currentUtilisateur.est_administrateur === 'O' && !this.currentUtilisateur.isInPerimetreAssociation(rowData['association'], rowData['federation']))
      || (rowData['statut_echange'].code_parametre === 'B' && this.currentUtilisateur.est_administrateur === 'N' && this.currentUtilisateur.id_utilisateur !== rowData['operateur'].id_utilisateur)) {
      classTrEchange += ' tr-echange-disabled';
    }

    if (rowData['type_echange'].code_parametre === 'PER') {
      classTrEchange += ' tr-echange-personia';
    }

    if (rowData['statut_echange'].code_parametre === 'A') {
      classTrEchange += ' tr-echange-astreinte';
    }

    return classTrEchange;
  }

  getCols(): void {
    this.cols = [
      { field: 'echange_fermable', header: ''},
      { field: 'marqueur', header: 'Marqueurs' },
      { field: 'created1', header: 'Date création' },
      { field: 'created2', header: 'Heure création' },
      { field: 'modified', header: 'Date modification'},
      { field: 'statut_echange', header: 'Statut échange' },
      { field: 'astreinte', header: 'Astreinte' },
      { field: 'operateur', header: 'Utilisateurs' },
      { field: 'personne_sujet_nom', header: 'Nom/Prénom' },
      { field: 'personne_sujet_nature', header: 'Nature' },
      { field: 'type_echange', header: 'Type' },
      { field: 'categorie_echange', header: 'Cat' },
      { field: 'motif_echange', header: 'Motif' },
      { field: 'actions', header: 'Actions ouvertes' },
      { field: 'statut_echeance', header: 'Statut Échéance'},
      { field: 'firstDateEcheance', header: 'Echéance' },
      { field: 'actions.responsable', header: 'Acteur' },
      { field: 'association', header: 'Association' },
    ];
  }

  getDataForExport(echangesList: Echange[] = this.echangesList) {
    let type_export = '';

    // Si une seule categorie dans les filtres => export spécifique
    if (this.filtreEchange
      && this.filtreEchange.categorie_echange && this.filtreEchange.categorie_echange.length === 1) {
      type_export = this.filtreEchange.categorie_echange[0].code_parametre;
    }

    let data = [];
    echangesList.forEach((echange: Echange) => {
      data = data.concat(echange.serializeForExport('', true));
    });

    return data;
  }

  getExportItems() {
    this.exportItems = [
      {
        label: 'Exporter la page',
        icon: 'zmdi zmdi-file',
        items: [
          {
            label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(false);
            }
          },
          {
            label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(false);
            }
          }
        ]
      },
      {
        label: 'Exporter tous les échanges',
        icon: 'zmdi zmdi-file-text',
        items: [
          {
            label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(true);
            }
          },
          {
            label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(true);
            }
          }
        ]
      }
    ];
  }

  getRowLabel(field, rowData) {
    if (field === 'statut_echange' || field === 'categorie_echange' || field === 'motif_echange') {
      return rowData[field] ? rowData[field].code_parametre : '';
    } else if (field === 'operateur' || field === 'personne_sujet_nature' || field === 'association') {
      return rowData[field] ? rowData[field].libelle : '';
    } else if (field === 'personne_sujet_nom') {
      let labelSujet = '';
      if (rowData[field]) {
        labelSujet += rowData[field];
      }
      if (rowData['personne_sujet_prenom']) {
        labelSujet += ' ' + rowData['personne_sujet_prenom'];
      }
      return labelSujet;
    } else if (field === 'type_echange') {
      let labelType = rowData[field] ? rowData[field].libelleTronque : '';
      if (rowData['type_echange_sens']) {
        labelType += ' ' + rowData['type_echange_sens'];
      }
      return labelType;
    } else if (field === 'actions') {
      return rowData['openActionsLength'] + '/' + rowData['actions'].length;
    } else if (field === 'actions.responsable') {
      let labelResponsable = '';
      if (rowData['actions'] && rowData['actions'].length > 0) {
        let nbActions = rowData['actions'].length;
        if (rowData['actions'][nbActions - 1].responsable) {
          labelResponsable += rowData['actions'][nbActions - 1].responsable.libelle;
        }
      }
      return labelResponsable;
    } else if (field === 'astreinte' || field === 'echange_fermable') {
      if (rowData[field] === null) {
        return '';
      } else {
        return (rowData[field] === true) ? 'Oui' : 'Non';
      }
    }

    return rowData[field];
  }

  getSortableColumnDisabled(field) {
    if (field === 'created2' || field === 'statut_echange' || field === 'personne_sujet_nom'
      || field === 'actions' || field === 'actions.responsable' || field === 'echange_fermable') {
      return true;
    }

    return false;
  }

  getStatutsAction() {
    this.parametreService.getParametresByParams({ type: 'STATUT_A' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsActionList = parametres;
      });
  }

  getStatutsEchange() {
    this.parametreService.getParametresByParams({ type: 'STATUT_E' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsEchangeList = parametres;
      });
  }

  getTooltip(field, rowData) {
    let tooltipLabel = null;
    if (field === 'personne_sujet_nom') {
      tooltipLabel = rowData['objet_echange'] ? rowData['objet_echange'] + '\n' : '';
      tooltipLabel += rowData['description'] ? rowData['description'] + '\n' : '';
    } else if (field === 'personne_sujet_nature' && rowData['hasTiers']) {
      if (rowData['personne_tiers_nom']) {
        tooltipLabel = rowData['personne_tiers_nom'];
      }
      if (rowData['personne_tiers_prenom']) {
        tooltipLabel += ' ' + rowData['personne_tiers_prenom'] + '\n';
      }
    } else if (field === 'actions' && rowData['actions'].length > 0) {
      tooltipLabel = '';
      rowData['actions'].forEach((action) => {
        tooltipLabel += ' (' + action.statut_action.code_parametre + ') ' + action.objet_action + '\n';
      });
    }

    if (tooltipLabel !== null && tooltipLabel !== '') {
      tooltipLabel.substring(0, tooltipLabel.length - 2); // On supprime le dernier retour a la ligne
    }

    return tooltipLabel;
  }

  loadEchangesLazy(event: LazyLoadEvent) {
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
    this.filtreEchange.offset = event.first;
    this.filtreEchange.limit = event.rows;
    this.filtreEchange.sort_property = FiltreEchange.getSortProperty(event.sortField);
    this.filtreEchange.sort_direction = FiltreEchange.getSortOrder(event.sortOrder);

    this.searchEchange(this.filtreEchange);
  }

  ngOnInit() {
    this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
      if (utilisateur) {
        this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;

        // Prise en compte de la préférence d'affichage juste avant le chargement de la page, après la 1ère connexion.
        // Voir : authentication.service.ts::login().
        if (sessionStorage.getItem('redirectPrefAffichage') !== null) {
          const preferenceAffichage = sessionStorage.getItem('redirectPrefAffichage');
          sessionStorage.removeItem('redirectPrefAffichage');
          if (preferenceAffichage === 'tableau_bord') {
            this.router.navigate(['in/tableau-bord']).then(() => {
            });
          }
        }
      }
    });

    this.getCols();
    this.getStatutsEchange();
    this.getStatutsAction();
    this.getExportItems();
  }

  onActionCreated(event) {
    this.selectedEchange.actions.push(event);
    this.showPopupAction = false;
  }

  openEchange(type_ouverture = '') {
    let params = {};
    this.statutsEchangeList.forEach((statut) => {
      if (statut.code_parametre === 'O') {
        this.selectedEchange.statut_echange = statut; // On met le statut de l'échange a ouvert
        params = { statut_echange: statut.id_parametre };
      }
    });

    let message = 'Echange rouvert avec succès.';
    if (type_ouverture === 'validation_brouillon') {
      message = 'Echange validé avec succès.';
    }

    // On met à jour l'échange
    this.echangeService.patchEchange(params, this.selectedEchange.id_echange)
      .subscribe((echangeUpdated: Echange) => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: message });
        this.searchEchange(this.filtreEchange); // On recharge la liste des echanges
      });
  }

  refresh() {
    this.checkAllEchanges = false;
    this.auMoinsUnCoche = false;
    this.refreshList.emit();
  }

  resetPagination() {
    this.dt.reset();
  }

  searchEchange(filtreEchange) {
    this.checkAllEchanges = false;
    this.auMoinsUnCoche = false;
    this.loading = true;
    this.filtreEchange.setDatas(filtreEchange);
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbTotalEchanges = result.count;
      });

    this.echangeService.getEchangesByParams(filtreEchange, this.updateFiltre)
      .subscribe((echanges: Echange[]) => {
          this.echangeService.echangesList = plainToClass(Echange, echanges); // On conserve le resultat pour naviguer dans les echanges sur la page echange
          this.echangesList = plainToClass(Echange, echanges);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });

  }

  updateContextMenuEchange(event) {
    /*
    Pour les boutons au clic droit :
    Rouvrir l'échange : échange fermé, droit modification de l'échange & ADMIN
    Valider le brouillon : échange au statut brouillon => il faut afficher le choix, possible de faire l'action seulement si tous les champs valides et je suis le créateur ou un admin avec le droit modif des échanges
    Fermer l'échange : échange ouvert, toutes les actions fermées, droit modification de l'échange
    Fermer toutes les actions : échange ouvert des actions sont ouvertes, droit contribution aux actions
    Ajouter une action : échange ouvert, droit contribution aux action
    */
    let selectedEchange = event.data;
    let validEchangeVisible = ((selectedEchange.statut_echange.code_parametre === 'B' && Echange.getForm(selectedEchange).valid && !Globals.isNullOrUndefined(selectedEchange.agence))
      && (this.currentUtilisateur.id_utilisateur === selectedEchange.operateur.id_utilisateur
        || (this.currentUtilisateur.est_administrateur === 'O' && this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges))));

    let reopenEchangeVisible = (selectedEchange.statut_echange.code_parametre === 'F'
      && this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
      && this.currentUtilisateur.est_administrateur === 'O');
    let closeEchangeVisible = selectedEchange.statut_echange.code_parametre === 'O' && selectedEchange.openActionsLength === 0
      && this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
      && Echange.getForm(selectedEchange).valid;
    let closeAllActionsVisible = selectedEchange.statut_echange.code_parametre === 'O' && selectedEchange.openActionsLength > 0 && this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_actions);
    let addActionVisible = selectedEchange.statut_echange.code_parametre === 'O' && this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_actions);
    let openActeursVisible = addActionVisible && selectedEchange.openActionsLength > 0;

    if (!validEchangeVisible && !reopenEchangeVisible && !closeEchangeVisible && !closeAllActionsVisible && !addActionVisible) {
      this.itemsContextMenu = [
        { label: 'Aucune action disponible' }
      ];
    } else {
      this.itemsContextMenu = [
        {
          label: 'Rouvrir l\'échange',
          icon: 'pi pi-folder-open',
          visible: reopenEchangeVisible,
          command: () => this.openEchange('reouverture_echange')
        },
        {
          label: 'Valider le brouillon',
          icon: 'pi pi-check',
          visible: validEchangeVisible,
          command: () => this.openEchange('validation_brouillon')
        },
        {
          label: 'Fermer l\'échange',
          icon: 'pi pi-times-circle',
          visible: closeEchangeVisible,
          command: () => this.closeEchange()
        },
        {
          label: 'Fermer toutes les actions',
          icon: 'pi pi-times',
          visible: closeAllActionsVisible,
          command: () => this.closeAllActions()
        },
        {
          label: 'Ajouter une action',
          icon: 'pi pi-plus-circle',
          visible: addActionVisible,
          command: () => this.addAction()
        },
        {
          label: 'Liste des acteurs',
          icon: 'pi pi-users',
          visible: openActeursVisible,
          command: () => this.openActeurs()
        }
      ];
    }
  }

  updateFiltreEchange(filtreEchange: FiltreEchange) {
    this.filtreEchange = filtreEchange;
  }

  isDateValid(value: any): boolean {
    const dateValue = new Date(value);
    return dateValue instanceof Date && !isNaN(dateValue.getTime());
  }

  openActeurs() {
    this.loadActeurs();
    this.displayActeurs = true;
  }

  loadActeurs() {
    this.acteurs = [];
    this.selectedEchange.actions.forEach((action) => {
      const acteur = {
        created: action.created || new Date().toISOString(),
        echeance: action.echeance ?? null,
        responsable_libelle: action.responsable?.libelle ?? null,
        objet_action: action.objet_action ?? '',
        description_action: action.description_action ?? '',
        statut_echeance: action.statut_echeance ?? ''
      };
      this.acteurs.push(acteur);
    });

    // On trie par date d'échéance chronologique :
    this.acteurs.sort((a, b) => {
      return this.getTime(a.echeance) - this.getTime(b.echeance);
    });
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  montrerDescriptifComplet(content: string) {
    this.descriptifCompletContenu = content;
    this.displayDescriptifComplet = true;
  }

  /**
   * Chaque checkbox d'un échange pouvant être fermé prend la valeur de la checkbox du TH.
   */
  basculerTout() {
    this.echangesList.forEach(echange => {
      if (echange.echange_fermable) {
        echange.a_fermer = this.checkAllEchanges;
      }
    });
    this.nbrEchangesAFermer();
  }


  async confirmFermetureMasse() {
    // Récupération du nombre d'échanges à fermer :
    let nbrEchanges = this.echangesList.filter(echange => echange.echange_fermable && echange.a_fermer).length;

    this.confirmationService.confirm({
      message: 'Confirmez-vous la fermeture ?',
      header: nbrEchanges + ' échange(s) sélectionné(s).',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      acceptIcon: 'pi pi-check',
      acceptButtonStyleClass: 'green-button',
      rejectLabel: 'Non',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'green-button',
      accept: async () => {
        let params = {};
        this.statutsEchangeList.forEach((statut) => {
          if (statut.code_parametre === 'F') {
            params = {statut_echange: statut.id_parametre};
          }
        });

        // On récupère les échanges pouvant être fermés que l'on veut fermer.
        let echanges = this.echangesList
          .filter(echange => echange.echange_fermable && echange.a_fermer);

        // On ferme les échanges concernés
        let observables = echanges.map((echange: Echange) => {
          return this.echangeService.patchEchange(params, echange.id_echange);
        });
        forkJoin(observables).subscribe(() => {
          // Message de fin et rechargement de la liste des échanges.
          this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Échange(s) fermé(s) avec succès.'});
          this.auMoinsUnCoche = false;
          this.searchEchange(this.filtreEchange);
        });
      },
      reject: () => {
        this.messageService.add({severity: 'info', summary: 'Annulation', detail: 'Annulation fermeture des échanges'});
      }
    });
  }

  nbrEchangesAFermer() {
    let nbrEchanges = this.echangesList.filter(echange => echange.echange_fermable && echange.a_fermer).length;
    if (nbrEchanges === 0) {
      this.checkAllEchanges = false;
    }
    this.auMoinsUnCoche = nbrEchanges > 0;
  }

  getStatutEcheanceLisible(statut: string): string {
    switch (statut) {
      case 'D':
        return 'Dépassée';
      case 'U':
        return 'Urgente';
      default:
        return statut;
    }
  }
}
